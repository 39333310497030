import(/* webpackMode: "eager" */ "/vercel/path0/apps/menu/app/globals.css");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/menu/app/store/provider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@vercel+speed-insights@1.0.12_next@14.1.4_react-dom@18.2.0_react@18.2.0__react@18.2.0__react@18.2.0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next-client-cookies@1.1.0_next@14.1.4_react-dom@18.2.0_react@18.2.0__react@18.2.0__react@18.2.0/node_modules/next-client-cookies/dist/provider.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.4_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/providers/client-push-service-provider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/providers/client-toast-provider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/translatable/client-translatable-text.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/ui/accordion.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/ui/alert-dialog.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/ui/checkbox.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/ui/combo-box.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/ui/command.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/ui/dialog.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/ui/dropdown-menu.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/ui/form.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/ui/label.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/ui/popover.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/ui/select.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/ui/sheet.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/ui/slider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/ui/switch.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/ui/tabs.tsx")